
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

// Components
import Info from '@/components/offers/icons/Info.vue';
import Location from '@/components/offers/icons/Location.vue';
import Modal from '../modal/Modal.vue';
import OfferDetails from './OfferDetails.vue';

import dayjs from 'dayjs';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

// Utilities
import { formatToUrl } from '@/utils/format-to-url';
import { boostOfferGraphic, cashOfferGraphic, locationIcon, offerOverlay } from '@/utils/image-paths';

// Types
import { OfferDetail, IMatching } from '@/types/offer.types';
import { IRedemption } from '@/types/redeemed-offers.types';

@Component({
  components: { Modal, OfferDetails, Location, Info }
})
export default class ExploreOfferCards extends Vue {
  @Prop() offer!: OfferDetail;
  @Prop() redemption!: IRedemption;

  @Prop() title!: string;
  @Prop() isRedeemedOffer!: boolean;
  @Prop() inprogress!: boolean;

  @Prop() comboOffer!: any;
  @Prop() offerCount!: number;

  @Ref('mainModel') modal!: any;

  location: string = locationIcon;
  boostOfferGraphicImg: string = boostOfferGraphic;
  cashOfferGraphicImg: string = cashOfferGraphic;
  offerOverlayImg: string = offerOverlay;

  cashForwardAmount = 0;
  offerBackground = '';
  matchingValuePercent = 0;
  currentBoosterPos = 3;
  sliderId = '';
  dayjs = dayjs;

  yellowArrow = tenants[ActiveTenant].yellowArrow;

  formatToUrl = formatToUrl;

  get rewardValue() {
    const value = this.offer.rewardValue;
    const type = this.offer.rewardType;
    return type === 'fixed' ? `$${value}` : `${value}%`;
  }

  get getBoosters(): IMatching[] {
    return this.offer.matchings
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  get getDonationValue(): string {
    var boostsValues = 0.0;
    this.offer.matchings.map((item) => {
      boostsValues += item.matchingValue;
    });

    const value = `${this.offer?.rewardValue}`.replace('$', '');
    const grandTotal: number = boostsValues + parseFloat(value ?? 0);

    return grandTotal.toFixed(2);
  }
}
